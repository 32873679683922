import React, {useState,  useRef} from 'react'
import { Link } from 'react-router-dom'
import Img01 from '../../assets/images/reservation_side_elements.jpeg'
import Img02 from '../../assets/images/calendar.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ReservOne() {
  const [startDate,  setStartDate] = useState(new Date());
  const nameRef = useRef("");
  const surnameRef = useRef("");
  const personRef = useRef("");
  const msgRef = useRef("");
  const timeRef = useRef("");

  const SubmitHandler = (e) =>{
      e.preventDefault()
   }

 const handleSubmit = (e) => {
      e.preventDefault();

      // Seçilen tarihleri formatlayın
      const formattedStartDate = formatDate(startDate);
   

      // Telefon numarası ve mesaj içeriğini oluşturun
      const phoneNumber = '+905335808682';
      const message = `Merhaba ben  ${nameRef.current.value}  ${surnameRef.current.value}, ${msgRef.current.value} ve saat ${timeRef.current.value} için ${formattedStartDate} günü   ${personRef.current.value} kişilik `+"  rezervasyon yapmak istiyorum. ";

      // WhatsApp linkini oluşturun
      const whatsappLink = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

      // WhatsApp linkine yönlendirme yapın
      window.open(whatsappLink);
  };

  const formatDate = (date) => {
      const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      return formattedDate;
  };
  return (
    <>
      <section className="bredcrumb_section resarvation_form reservationpage_1_bg">
        <div className="side_shape position-absolute">
          <img src={Img01} alt="img" />
        </div>
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="icon">
              <img src={Img02} alt="img" />
            </span>
            <span className="sub_text">Online reservation</span>
            <h2>The Olive Tree Restaurant & Bar Hisarönü / Fethiye </h2>
          </div>
          <div className="form_inner" data-aos="fade-in" data-aos-duration={1500} data-aos-delay={150} >
            <div className="formBlock">
              <div className="text-center">
                <h2 className="mb-2">Book your delicious table now (+18 & Adults only) 
                </h2>
                <p>
                  Booking request <Link to="tel:+902526166361">+90 252 616 63 61</Link> or
                  fill out the order form
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                     <div className="form-group">
                      <input type="text" className="form-control" placeholder="Your Name" ref={nameRef} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Surname" ref={surnameRef} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group">
                      <span className="icon">
                        <i className="icofont-user-alt-7" />
                      </span>
                      <input type="text" className="form-control has_icon" placeholder="1 Person" ref={personRef} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="icon">
                        <i className="icofont-calendar" />
                      </span>
                     {/*  <input type="date" className="form-control  flex-row-reverse" /> */}
                     <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <span className="icon">
                        <i className="icofont-clock-time" />
                      </span>
                      <input type="time" className="form-control has_icon  flex-row-reverse pl-0" ref={timeRef} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Message" defaultValue={""} ref={msgRef} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button className="btn btn_primary w-100 ">book a table</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="contact_block">
              <h2>Contact Us</h2>
              <div className="booking">
                <h3>Booking request</h3>
                <Link to="tel:+902526166361">+90 252 616 63 61</Link>
              </div>
              <ul>
                <li>
                  <h3>Location</h3>
                  <p>
                  The Olive Tree Restaurant & Bar <br />
                  Hisarönü / Fethiye 
                  </p>
                </li>
                <li>
                  <h3>Lunch Time</h3>
                  <p>
                    Monday to Sunday <br />
                    11.00 am - 2.30pm
                  </p>
                </li>
                <li>
                  <h3>Dinner Time</h3>
                  <p>
                    Monday to Sunday <br />
                    5.30 pm - 11.30 pm
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReservOne