import React from 'react'
import Dish01 from '../../assets/images/dish.png'
import Icon01 from '../../assets/images/outj_icon1.png'
import Icon02 from '../../assets/images/outj_icon2.png'
import Icon03 from '../../assets/images/outj_icon3.png'

function Journey() {
  return (
    <>
      <section className="row_am our_journey bg_white_textcher">
        <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
          <img src={Dish01} alt="img" />
        </div>
        <div className="container">
          <div className="row_am">
            <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
              <span className="sub_text">Why we best</span>
              <h2>
              The Olive Tree Restaurant & Bar  <br /> Hakkında
              </h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon01} alt="img" />
                  </div>
                  <h3>Prime location</h3>
                  <p>
               
The Olive Tree Restaurant , established in 1994 and operated by Mustafa Culban, 

Offered the Diner both fine food and excellent service. Situated in the heart of Hisarönü, it was extremely succesful and widely renowned. 

It enjoyed many years as one of the top Restaurants in the area. 
Now situated just 200 metres though the lower pedestrion barrier on the kayaköy road, this beautiful Restaurant is open on an adults basis(14 years upward). Offering a unique dining experience. 


                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon02} alt="img" />
                  </div>
                  <h3>Wonderfull scenery</h3>
                  <p>
                  Dining under the star sor inside,lends itself to this tremendous setting.
                  The Olive Tree Restaurant is a place where you can enjoy a romantic meal, a special occasion or just a night out with friends.

                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon03} alt="img" />
                  </div>
                  <h3>premium services</h3>
                  <p>
                  The Olive Tree Restaurant is a place where you can enjoy a romantic meal, a special occasion or just a night out with friends. A warm welcome awaits you. Only the finest food, served by immaculate staff will leave the visitors with an unforgettable experience.
                  In 2009 , with the Village expanding, a more tranquil setting for the more disceming Diner was required. 




                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Journey