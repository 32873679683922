import React from 'react'
import { Link } from 'react-router-dom'
import Blog01 from '../../assets/images/blog-list-1.png'
import Blog02 from '../../assets/images/author1.png'
import Blog03 from '../../assets/images/blog-list-2.jpeg'
import Blog04 from '../../assets/images/author2.png'
import Blog05 from '../../assets/images/blog-list-3.jpeg'
import Blog06 from '../../assets/images/author1.png'
import Blog07 from '../../assets/images/blog-list-4.jpeg'
import Blog08 from '../../assets/images/author2.png'
import Blog09 from '../../assets/images/blog-recent-1.png'
import Blog10 from '../../assets/images/blog-recent-2.png'
import Blog11 from '../../assets/images/blog-recent-3.png'
import Blog12 from '../../assets/images/blog-recent-4.png'

function BlogList() {
  return (
    <>
      <section className="blog_list_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog_left_side">
                <div className="blog_panel" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="main_img">
                    <Link to="Cl">
                      <img src={Blog01} alt="image" />
                    </Link>
                  </div>
                  <div className="blog_info">
                    <span className="date">12 Dec, 2022</span>
                    <h2>
                      <Link to="/blogdetail1">
                      Celebrating 30th. Anniversary ✨✨✨
May 1994 - May 2024
                      </Link>
                    </h2>
                    <p>
                    We are proudly celebrating the 30th anniversary of the establishment of our restaurant, every day of which has been full of achievements for us. Since 1994, we are proud to be one of the best in its field with its ever-increasing service quality. We wholeheartedly agree with the saying that great things are accomplished by employees who dare to do them, and we believe that we will achieve even greater successes together in the future. We would like to thank the employees of The Olive Tree Family who have been with us since our establishment and who have devoted their experience and knowledge, heart and labor to the development of The Olive Tree Restaurant. While expressing our sincere gratitude to our guests who have supported us during these 30 years, our goal is to win the appreciation and admiration of those who cooperate with us by offering the best, much better, and to win the appreciation and admiration of those who join us in the future... Thank you .... ❤️
                    </p>
                    <p>
Restoranımızın kuruluşunun, bizler için her günü başarılarla dolu olan 30. Yılını gururla kutluyoruz. 1994 yılından bu yana sürekli yükselen hizmet kalitesiyle alanında en iyilerinden olmanın da haklı mutluluğunu yaşıyoruz. Harikulade işler, onu yapma cesaretini gösteren çalışanlar tarafından başarıyla gerçekleştirilirler sözüne yürekten katılıyor, gelecekte de daha büyük başarılara hep birlikte imza atacağımıza inanıyoruz. Kuruluşumuzdan bu yana bizimle beraber olan tecrübe ve bilgisini, kalbi ve emeğini The Olive Tree Restaurant’ın gelişimi için adayan The Olive Tree Ailesi çalışanlarına çok teşekkür ediyoruz. Geçen bu 30 yıl içerisinde bize destek veren misafirlerimize gönülden şükranlarımızı sunarken, hedefimiz; iyiyi, çok daha iyiyi sunarak, bizimle işbirliği yapan, dirsek temasında bulunanların gelecekte de takdir ve beğenilerini kazanmaktır… İyi ki varsınız.... ❤️
                    </p>
                    <div className="authore_block">
                      <div className="authore">
                        <div className="img">
                          <img src={Blog02} alt="image" />
                        </div>
                        <div className="text">
                          <h4>Admin</h4>
                          <span>The Olive Tree Restaurant</span>
                        </div>
                      </div>
                      <div className="blog_tag">
                        <span>anniversary</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog_panel" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="main_img">
                    <Link to="/blogdetail2">
                      <img src={Blog03} alt="image" />
                    </Link>
                  </div>
                  <div className="blog_info">
                    <span className="date">March 18, 2023</span>
                    <h2>
                      <Link to="/blogdetail2">
                      Unveiling the Culinary Delights of Fethiye at The Olive Tree Restaurant & Bar 
                      </Link>
                    </h2>
                    <p>
                    Embark on a delicious adventure through the flavors of Fethiye at The Olive Tree Restaurant & Bar! Nestled in the charming village of Hisarönü, our restaurant boasts a rich culinary heritage dating back to 1994.  We invite you to savor the essence of the Mediterranean with our delectable dishes, crafted using the freshest local ingredients and traditional cooking techniques.

<h6>A Fusion of Flavors:</h6>


Our menu is a delightful fusion of international favorites and regional specialties.  For the adventurous foodie, tantalize your taste buds with our signature [Dish Name], a unique blend of [ingredients] that will leave you wanting more.  Craving comfort food?  Our [Dish Name] is a haven of familiar flavors, prepared with the utmost care.  No matter your palate, The Olive Tree offers a culinary journey to remember.
<h6>Unparalleled Wine Pairings:</h6>


Complement your meal with the perfect glass of wine from our extensive wine list. Featuring a curated selection of local and international wines, our knowledgeable staff can guide you towards the ideal pairing that enhances the flavors of your chosen dish. Whether you're a seasoned wine connoisseur or just starting to explore the world of wines, we're here to help you discover a new favorite.
<h6>Beyond the Plate: A Relaxing Escape</h6>

The Olive Tree Restaurant & Bar extends beyond just a dining experience.  Immerse yourself in the tranquility of our beautiful outdoor seating, complete with a refreshing poolside setting. As you savor your meal, take in the breathtaking views of the surrounding landscape, creating a truly unforgettable evening.

Ready to embark on your own culinary adventure?  Make a reservation at The Olive Tree Restaurant & Bar today and experience the taste of Fethiye!
                    </p>
                    <div className="authore_block">
                      <div className="authore">
                        <div className="img">
                          <img src={Blog04} alt="image" />
                        </div>
                        <div className="text">
                          <h6>The Olive Tree Restaurant</h6>
                          <span>Admin</span>
                        </div>
                      </div>
                      <div className="blog_tag">
                        <span>Keywords: Fethiye, Hisarönü, restaurant, bar, Turkish cuisine, Mediterranean food, international cuisine, wine list, poolside dining, outdoor seating, local ingredients, fresh seafood, romantic dinner, family-friendly</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog_panel" data-aos="fade-up" data-aos-duration={1500}>
                  <div className="main_img">
                    <Link to="/blogdetail3">
                      <img src={Blog05} alt="image" />
                    </Link>
                  </div>
                  <div className="blog_info">
                    <span className="date">05 Dec, 2022</span>
                    <h2>
                      <Link to="/blogdetail3">
                      Fethiye'nin Gözde Lezzet Durağı: The Olive Tree Restaurant & Bar
                      </Link>
                    </h2>
                    <p>
                    Ölüdeniz'in masmavi lagününün hemen yakınında bulunan Hisarönü'ndeki Zeytin Ağacı Restoran & Bar, Fethiye'nin harikalarını keşfeden gezginler için enfes bir kaçamak sunuyor. 1994 yılından bu yana hizmet veren restoranımız, zengin mutfak mirasıyla Akdeniz'in ruhunu en taze yerel malzemeler ve geleneksel pişirme teknikleriyle yakalıyor.
<h6>Fethiye Maceranızda Mükemmel Bir Mola</h6>


İster masmavi Ölüdeniz lagününde serinletici bir yüzmenin ardından dönüyor olun, ister cennet gibi Gemiler Adası'na ve plajına günübirlik bir geziye çıkıyor olun, Zeytin Ağacı damak zevkinizi şımartmak için mükemmel bir durak. Bu popüler destinasyonlara giden yol üzerindeki konumunuz sayesinde, Fethiye rotanıza keyifli bir yemek deneyimini sorunsuz bir şekilde entegre edebilirsiniz.
<h6>Damak Tadınızı Şenlendirecek Lezzetler</h6>


Menümüz, uluslararası favoriler ve yöresel spesiyalitelerin harika bir füzyonu.  Maceraperest gurmeler için, daha fazlasını istemenize neden olacak benzersiz [malzemeler] karışımı olan imza yemeğimiz [Yemek İsmi]'ni deneyin. Rahatlatıcı bir yemek mi arıyorsunuz? [Yemek İsmi] özenle hazırlanmış tanıdık tatların bir limanı. Damak zevkiniz ne olursa olsun, Zeytin Ağacı unutulmaz bir yemek yolculuğu sunuyor.
<h6>Eşsiz Şarap Eşleşmeleri</h6>


Zengin şarap listemizden mükemmel bir kadeh şarap ile yemeğinizi tamamlayın. Yerel ve uluslararası şaraplardan özenle seçilmiş bir koleksiyon sunan bilgili personelimiz, seçtiğiniz yemeğin tatlarını zenginleştiren ideal eşleşme konusunda size rehberlik edebilir. İster deneyimli bir şarap uzmanı olun, ister şarap dünyasını keşfetmeye yeni başlıyor olun, yeni bir favori keşfetmenize yardımcı olmaya hazırız.
<h6>Tabağın Ötesinde: Rahatlatıcı Bir Kaçamak</h6>


Zeytin Ağacı Restoran & Bar sadece bir yemek deneyiminin ötesine geçiyor. Serinletici havuz başı ortamı ile tamamlanan güzel açık alan oturma yerlerimizin huzuruna kendinizi bırakın. Yemeğinizin tadını çıkarırken, çevredeki manzarayı seyrederek gerçekten unutulmaz bir akşam yaratın.
Fethiye'nin harikalarını keşfettikten sonra kendi mutfak maceranıza atılmaya hazır mısınız? Bugün Zeytin Ağacı Restoran & Bar'da rezervasyon yaptırın ve Fethiye'nin tadını çıkarın!
                    </p>
                    <div className="authore_block">
                      <div className="authore">
                        <div className="img">
                          <img src={Blog06} alt="image" />
                        </div>
                        <div className="text">
                          <h4>The Olive Tree Restaurant & Bar</h4>
                          <span>Admin</span>
                        </div>
                      </div>
                      <div className="blog_tag">
                        <span>tasty menu, Fethiye, Hisarönü, restoran, bar, Türk mutfağı, Akdeniz yemekleri, uluslararası mutfak, şarap listesi, havuz başı yemekleri, açık oturma alanı, yerel malzemeler, taze deniz ürünleri, romantik akşam yemeği, aile dostu, Ölüdeniz Mavi Lagün, Gemiler Adası, plaj</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog_panel" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="main_img">
                    <Link to="/blogdetail4">
                      <img src={Blog07} alt="image" />
                    </Link>
                  </div>
                  <div className="blog_info">
                    <span className="date">02 Dec, 2022</span>
                    <h2>
                      <Link to="/blogdetail4">
                      A Hidden Oasis: Unwind Poolside at The Olive Tree Restaurant & Bar
                      </Link>
                    </h2>
                    <p>
                    Seeking a relaxing escape in the heart of Hisarönü? Look no further than The Olive Tree Restaurant & Bar! Our tranquil poolside setting offers the perfect oasis to unwind, soak up the sun, and indulge in delectable food and drinks.
<h6>Escape the Ordinary:
</h6>

Step away from the hustle and bustle and discover a haven of serenity.  Our inviting poolside area provides the ideal setting to relax with friends or family. Take a dip in the refreshing pool, work on your tan on a comfortable sun lounger, or simply unwind with a good book.

<h6>Culinary Delights by the Pool:
</h6>

While you relax, our attentive staff is on hand to cater to your every need.  Enjoy a refreshing poolside cocktail or indulge in a light lunch from our delectable menu. From fresh salads and wraps to tempting appetizers, we offer a variety of options to satisfy your cravings without leaving the poolside comfort.

<h6>Unwind and Savor the Evening:
</h6>


As the sun begins to set, the poolside area transforms into a magical setting for an unforgettable evening.  Enjoy a delicious dinner under the starlit sky, accompanied by the gentle sound of poolside relaxation.  The Olive Tree promises an evening of tranquility and pure indulgence.
                    </p>
                    <p>Escape the ordinary and discover your own poolside oasis at The Olive Tree Restaurant & Bar.  Book your table today and experience the perfect blend of relaxation and culinary delight!</p>
                    <div className="authore_block">
                      <div className="authore">
                        <div className="img">
                          <img src={Blog08} alt="image" />
                        </div>
                        <div className="text">
                          <h4>The Olive Tree Restaurant & Bar</h4>
                          <span>Admin</span>
                        </div>
                      </div>
                      <div className="blog_tag">
                        <span>Hisarönü, poolside dining, restaurant, bar, cocktails, drinks, sun loungers, swimming pool, relaxation, oasis, unwind, family-friendly, romantic dinner, sunset views</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog_right_side">
          {/*       <div className="blog_search_block bg_box" data-aos="fade-up" data-aos-duration={1500} >
                  <form action="submit">
                    <div className="form-group">
                      <h3>Search post</h3>
                      <div className="form_inner">
                        <input type="text" className="form-control" />
                        <button>
                          <i className="icofont-search-1" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="recent_post_block bg_box" data-aos="fade-up" data-aos-duration={1500} >
                  <h3>Recent Post</h3>
                  <ul className="recent_blog_list">
                    <li>
                      <Link to="/blogdetail">
                        <div className="img">
                          <img src={Blog09} alt="image" />
                        </div>
                        <div className="text">
                          <h4>Famous Dishes, Delicious Cakes and Fresh Drinks</h4>
                          <span>Feb 25, 2023</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogdetail">
                        <div className="img">
                          <img src={Blog10} alt="image" />
                        </div>
                        <div className="text">
                          <h4>Unforgettable Memories From Europe Journey</h4>
                          <span>Feb 20, 2023</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogdetail">
                        <div className="img">
                          <img src={Blog11} alt="image" />
                        </div>
                        <div className="text">
                          <h4>A restaurant with Traditional delicious menu.</h4>
                          <span>Feb 14, 2023</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogdetail">
                        <div className="img">
                          <img src={Blog12} alt="image" />
                        </div>
                        <div className="text">
                          <h4>A rare taste you can’t find anywhere in Newyork</h4>
                          <span>Feb 09, 2023</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="categories_block bg_box" data-aos="fade-up" data-aos-duration={1500} >
                  <h3>Categories</h3>
                  <ul>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Best Steaks
                      </Link>
                      <span>(30)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Real Steaks{" "}
                      </Link>
                      <span>(22)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Tasty Food
                      </Link>
                      <span>(12)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Foodstagram
                      </Link>
                      <span>(48)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Yummy
                      </Link>
                      <span>(58)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Foodie
                      </Link>
                      <span>(17)</span>
                    </li>
                    <li>
                      <Link to="#" className="cat">
                        <i className="icofont-folder-open" /> Delicious
                      </Link>
                      <span>(42)</span>
                    </li>
                  </ul>
                </div> */}
                <div className="tags_block bg_box" data-aos="fade-up" data-aos-duration={1500} >
                  <h3>Tags</h3>
                  <ul>
                    <li>
                      <Link to="#">Steak</Link>
                    </li>
                    <li>
                      <Link to="#">Breakfast</Link>
                    </li>
                    <li>
                      <Link to="#">Akşam yemeği</Link>
                    </li>
                    <li>
                      <Link to="#">Restoran</Link>
                    </li>
                    <li>
                      <Link to="#">Real Steaks</Link>
                    </li>
                    <li>
                      <Link to="#">Dinner</Link>
                    </li>
                    <li>
                      <Link to="#">Fethiye</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         {/*  <div className="pagination_block" data-aos="fade-up" data-aos-duration={1500} >
            <div className="row">
              <div className="col-lg-8">
                <ul>
                  <li>
                    <Link to="#" className="prev">
                      <i className="icofont-double-left" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">1</Link>
                  </li>
                  <li>
                    <Link to="#" className="active">
                      2
                    </Link>
                  </li>
                  <li>
                    <Link to="#">3</Link>
                  </li>
                  <li>
                    <Link to="#">4</Link>
                  </li>
                  <li>
                    <Link to="#" className="next">
                      <i className="icofont-double-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default BlogList