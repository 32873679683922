import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Banner from '../Menhome/Banner'
import Deliciousmenu from '../Menhome/Deliciousmenu'
import PrivateSection from '../Menhome/PrivateSection'
import Review from '../Menhome/Review'
import Blog from '../Menhome/Blog'
import Footer from '../Footer/Main'
import WhoWe from '../Menhome/WhoWe'
import MenuList from '../Menhome/MenuList'
import Aos from 'aos'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import WhatsappPhoto from '../../assets/images/logo.png';


function Main() {
const [msg, setMsg] = useState("");
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div className='page_wrapper'>
      <Header />
      <Banner />
      <MenuList />
      <Deliciousmenu />
      <WhoWe />
      <Review />
      <PrivateSection />
      <Blog />
      <Footer />
      <FloatingWhatsApp
        phoneNumber="+905335808682"
        accountName="THE OLIVE TREE"
        chatMessage="Hi there! Welcome to THE OLIVE TREE Restaurant & Bar. How can I help?"
        avatar={WhatsappPhoto}
        statusMessage="Open"
        placeholder="Write..."
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
  )
}


export default Main