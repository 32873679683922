import React from 'react'
import AboutImg from '../../assets/images/abtbanshape.png'
import Icon01 from '../../assets/images/title_icon.png'
import abt01 from '../../assets/images/abtban_01.jpeg'
import abt02 from '../../assets/images/abtban_02.jpeg'
import abt03 from '../../assets/images/abtban_03.jpeg'
import abt04 from '../../assets/images/abtban_04.jpeg'
import abt05 from '../../assets/images/abtban_05.jpeg'

function AbtBanner() {
  return (
    <>
      <section className="abt_banner">
        <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div>
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
            Our Story{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
          THE OLIVE TREE RESTAURANT & BAR<br /> HISARONU / FETHIYE
          </h2>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
          Offering a unique dining experience, we offer our guests both fine food and excellent, warm service. Situated at the quiet end of Hisarönü on the way to Kaya Village, The Olive Tree is an extremely popular and well-regarded restaurant with many guests returning year after year. Our restaurant & bar are open to adults only.(over 14) Please book ahead to avoid disappointment.
          </p>
        </div>
        <div className="abt_img">
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt01} alt="img" />
          </div>
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt04} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
  )
}

export default AbtBanner