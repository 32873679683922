import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/menulist_03.png'
import Img2 from '../../assets/images/menulist-baklava.jpg'
import Img3 from '../../assets/images/menulist-tiramisu.jpg'
import Img4 from '../../assets/images/menulist-cheesecake.jpg'
import Img5 from '../../assets/images/menulist-creme-brulee.jpg'
import Img6 from '../../assets/images/menulist-ice-cream.jpg'
import Img7 from '../../assets/images/menulist-pancake.jpg'
// import Img5 from '../../assets/images/menulistSub_04.png'

function Dishlist2() {
    return (
        <>
            <section className="row_am dishList_block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_img dish_left">
                                <img className="img-fluid" src={Img1} alt="img" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img src={Img2} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Baklava with Ice Cream</h4>
                                                    <span className="new">NEW</span>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Indulge in a warm, flaky baklava paired with your choice of rich vanilla or nutty pistachio ice cream. it's a delightful blend of sweet and creamy in every bite.                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Tiramisu</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Experience the classic elegance of our Tiramisu: layers of coffee-soaked ladyfingers and velvety mascarpone cream dusted with cocoa powder. Light, luscious, and irresistibly smooth, this Italian dessert is a must-try indulgence.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img4} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Lemon Cheesecake</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Indulge in our zesty Lemon Cheesecake, featuring a buttery graham cracker crust and a smooth, creamy lemon-infused filling. Topped with a vibrant lemon glaze and a touch of zest, this refreshing dessert is a delightful balance of sweet and tart.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img5} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Creme Brulee</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Savor the timeless elegance of our Crème Brûlée, a silky vanilla custard topped with a perfectly caramelized sugar crust. Crack through the golden, crispy shell to reveal the creamy richness beneath—this classic French dessert is pure decadence.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img6} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Ice Cream 3 Balls</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Served strawberry or chocolate sauce                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img7} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Choice of Pancake</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Customize your breakfast with our choice of pancakes                                          
                                                                                             </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="/reservation1" className="btn btn_primary">
                                    book a table
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dishlist2