import React from "react";
import { Link } from "react-router-dom";
import Blog1 from '../../assets/images/logo-site.png'
import Blog2 from '../../assets/images/blog-single2.jpeg'

function BlogDetail() {
  return (
    <>
      <section className="blog_detail_section" data-aos="fade-up" data-aos-duration={2000} data-aos-delay={200} >
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="blog_info" data-aos="fade-up" data-aos-duration={2000} >
              <span className="date">March 23, 2023</span>
              <h1>Fethiye'nin Gözde Lezzet Durağı: The Olive Tree Restaurant & Bar</h1>
              <div className="authore_block" data-aos="fade-up" data-aos-duration={1000} >
                <div className="authore">
                  <div className="img">
                    <img src={Blog1} alt="image" />
                  </div>
                  <div className="text">
                    <h4></h4>
                    <span></span>
                  </div>
                </div>
                <div className="blog_tag">
                  <span>tasty menu, Fethiye, Hisarönü, restoran, bar, Türk mutfağı, Akdeniz yemekleri, uluslararası mutfak, şarap listesi, havuz başı yemekleri, açık oturma alanı, yerel malzemeler, taze deniz ürünleri, romantik akşam yemeği, aile dostu, Ölüdeniz Mavi Lagün, Gemiler Adası, plaj</span>
                </div>
              </div>
              <p>
              Ölüdeniz'in masmavi lagününün hemen yakınında bulunan Hisarönü'ndeki Zeytin Ağacı Restoran & Bar, Fethiye'nin harikalarını keşfeden gezginler için enfes bir kaçamak sunuyor. 1994 yılından bu yana hizmet veren restoranımız, zengin mutfak mirasıyla Akdeniz'in ruhunu en taze yerel malzemeler ve geleneksel pişirme teknikleriyle yakalıyor.
              </p>
            </div>
            <div className="main_img">
              <img src={Blog2} alt="image" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Fethiye Maceranızda Mükemmel Bir Mola</h2>
              <p>
              İster masmavi Ölüdeniz lagününde serinletici bir yüzmenin ardından dönüyor olun, ister cennet gibi Gemiler Adası'na ve plajına günübirlik bir geziye çıkıyor olun, Zeytin Ağacı damak zevkinizi şımartmak için mükemmel bir durak. Bu popüler destinasyonlara giden yol üzerindeki konumunuz sayesinde, Fethiye rotanıza keyifli bir yemek deneyimini sorunsuz bir şekilde entegre edebilirsiniz.

              </p>
            </div>
            <div className="quote_block" data-aos="fade-up" data-aos-duration={1500} >
              <span className="q_icon">“</span>
              <h3>
              Damak Tadınızı Şenlendirecek Lezzetler              </h3>
              <span className="q_icon">”</span>
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Global Lezzetler</h2>
              <p>
              Menümüz, global favoriler ve yöresel spesiyalitelerin harika bir füzyonu.  Maceraperest gurmeler için, daha fazlasını istemenize neden olacak benzersiz [malzemeler] karışımı olan imza yemeğimiz [Yemek İsmi]'ni deneyin. Rahatlatıcı bir yemek mi arıyorsunuz? [Yemek İsmi] özenle hazırlanmış tanıdık tatların bir limanı. Damak zevkiniz ne olursa olsun, Zeytin Ağacı unutulmaz bir yemek yolculuğu sunuyor.
 <br /> <br />
 <h6>Eşsiz Şarap Eşleşmeleri</h6>

 Zeytin Ağacı Restoran & Bar sadece bir yemek deneyiminin ötesine geçiyor. Serinletici havuz başı ortamı ile tamamlanan güzel açık alan oturma yerlerimizin huzuruna kendinizi bırakın. Yemeğinizin tadını çıkarırken, çevredeki manzarayı seyrederek gerçekten unutulmaz bir akşam yaratın.
Fethiye'nin harikalarını keşfettikten sonra kendi mutfak maceranıza atılmaya hazır mısınız? Bugün Zeytin Ağacı Restoran & Bar'da rezervasyon yaptırın ve Fethiye'nin tadını çıkarın!
                    

              </p>
            </div>
            <div className="main_img" data-aos="fade-up" data-aos-duration={1500} >
              <iframe style={{ width: "100%", height: 500 }}
                src="https://www.youtube.com/embed/zIOt1faGd7g"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Neden tercih ediliyoruz?</h2>
              <ul>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    {" "}
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    30 Yıllık Deneyim
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Lezzetli Akdeniz Mutfağı
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Kusursuz Hizmet Anlayışı.{" "}
                  </p>
                </li>
              </ul>
            </div>
           
            <div className="blog_authore" data-aos="fade-up" data-aos-duration={1500} >
              <div className="social_media">
                <h3>Share this</h3>
                <ul>
                  <li>
                    <Link to="#">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
