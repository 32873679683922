import React from "react";
import { Link } from "react-router-dom";
import Blog1 from '../../assets/images/logo-site.png'
import Blog2 from '../../assets/images/blog-single2.jpeg'

function BlogDetail() {
  return (
    <>
      <section className="blog_detail_section" data-aos="fade-up" data-aos-duration={2000} data-aos-delay={200} >
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="blog_info" data-aos="fade-up" data-aos-duration={2000} >
              <span className="date">March 23, 2023</span>
              <h1>AUnveiling the Culinary Delights of Fethiye at The Olive Tree Restaurant & Bar </h1>
              <div className="authore_block" data-aos="fade-up" data-aos-duration={1000} >
                <div className="authore">
                  <div className="img">
                    <img src={Blog1} alt="image" />
                  </div>
                  <div className="text">
                    <h4></h4>
                    <span></span>
                  </div>
                </div>
                <div className="blog_tag">
                  <span>Real steak, Fethiye, Hisarönü, restaurant, bar, Turkish cuisine, Mediterranean food, international cuisine, wine list, poolside dining, outdoor seating, local ingredients, fresh seafood, romantic dinner, family-friendly</span>
                </div>
              </div>
              <p>
              Embark on a delicious adventure through the flavors of Fethiye at The Olive Tree Restaurant & Bar! Nestled in the charming village of Hisarönü, our restaurant boasts a rich culinary heritage dating back to 1994.  We invite you to savor the essence of the Mediterranean with our delectable dishes, crafted using the freshest local ingredients and traditional cooking techniques.
              </p>
            </div>
            <div className="main_img">
              <img src={Blog2} alt="image" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>A Fusion of Flavors:</h2>
              <p>
              Our menu is a delightful fusion of international favorites and regional specialties.  For the adventurous foodie, tantalize your taste buds with our signature [Dish Name], a unique blend of [ingredients] that will leave you wanting more.  Craving comfort food?  Our [Dish Name] is a haven of familiar flavors, prepared with the utmost care.  

              </p>
            </div>
            <div className="quote_block" data-aos="fade-up" data-aos-duration={1500} >
              <span className="q_icon">“</span>
              <h3>
              No matter your palate, The Olive Tree offers a culinary journey to remember.
              </h3>
              <span className="q_icon">”</span>
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Unparalleled Wine Pairings:</h2>
              <p>
              Complement your meal with the perfect glass of wine from our extensive wine list. Featuring a curated selection of local and international wines, our knowledgeable staff can guide you towards the ideal pairing that enhances the flavors of your chosen dish. Whether you're a seasoned wine connoisseur or just starting to explore the world of wines, we're here to help you discover a new favorite.
 <br /> <br />
 <h6>Beyond the Plate: A Relaxing Escape</h6>

The Olive Tree Restaurant & Bar extends beyond just a dining experience.  Immerse yourself in the tranquility of our beautiful outdoor seating, complete with a refreshing poolside setting. As you savor your meal, take in the breathtaking views of the surrounding landscape, creating a truly unforgettable evening.


              </p>
            </div>
            <div className="main_img" data-aos="fade-up" data-aos-duration={1500} >
              <iframe style={{ width: "100%", height: 500 }}
                src="https://www.youtube.com/embed/zIOt1faGd7g"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Neden tercih ediliyoruz?</h2>
              <ul>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    {" "}
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    30 Yıllık Deneyim
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Lezzetli Akdeniz Mutfağı
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Kusursuz Hizmet Anlayışı.{" "}
                  </p>
                </li>
              </ul>
            </div>
           
            <div className="blog_authore" data-aos="fade-up" data-aos-duration={1500} >
              <div className="social_media">
                <h3>Share this</h3>
                <ul>
                  <li>
                    <Link to="#">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
