import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/menulist-sezar.jpg'
import Img2 from '../../assets/images/menulist-tuna-fish.jpg'
import Img3 from '../../assets/images/menulist-halloumiSalad.jpg'
import Img4 from '../../assets/images/menulist-cajun-salmon.jpg'
import Img5 from '../../assets/images/menulist_02.png'

function Dishlist1() {
    return (
        <>
            <section className="row_am dishList_block">
                <div className="container">
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img src={Img1} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Caeser</h4>
                                                    <span className="new">NEW</span>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Fresh romaine lettuce, croutons, and parmesan cheese
                                                    tossed in caesar dressing.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img2} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Tuna Fish</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                Tuna fish, mixed greens, cherry tomatoes, cucumbers, red onions, and olives.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Grilled Chicken&H alloumiSalad</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Grilled chicken, halloumi cheese, mixed greens, cherry
                                                    tomatoes, cucumbers, red onions, and olives.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img4} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Cajun Salmon</h4>
                                                </div>
                                                <div className="doted" />
                                                <span className="price"></span>
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Cajun salmon, mixed greens, cherry tomatoes, cucumbers,
                                                    red onions, and olives.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="/reservation1" className="btn btn_primary">
                                    book a table
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_img dish_right">
                                <img className="img-fluid" src={Img5} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dishlist1