import React,{useEffect} from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import MenuList1Bred from '../BredCrumb/MenuList1Bred'
import TextSteak from '../MenMenuList/TextSteak'
import TextLamb from '../MenMenuList/TextLamb'
import Steak from '../MenMenuList/Steak'
import Lamb from '../MenMenuList/Lamb'
import TextSalad from '../MenMenuList/TextSalad'
import Salad from '../MenMenuList/Salad'
import TextDesserts from '../MenMenuList/TextDesserts'
import Desserts from '../MenMenuList/Desserts'
import Reservation from '../MenMenuList/Reservation'
import Aos from 'aos'



function Main() {

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div className='page_wrapper'>
      <Header />
      <MenuList1Bred />
      <TextSteak />
      <Steak/>
      <TextLamb />
      <Lamb/>
      <TextSalad /> 
      <Salad />
      <TextDesserts />
      <Desserts />
     {/*  <Reservation/>   */}  
      <Footer />
    </div>
  )
}

export default Main