import React from 'react'

function BlogListBred() {
  return (
    <div>
      <section className="bredcrumb_section blog_list_page">
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">Latest Blog Post</span>
            <h2>Celebrating 30th. Anniversary ✨✨✨
May 1994 - May 2024
</h2>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BlogListBred