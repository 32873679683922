import React from "react";
import { Link } from "react-router-dom";
import Blog1 from '../../assets/images/logo-site.png'
import Blog2 from '../../assets/images/blog-single2.jpeg'

function BlogDetail() {
  return (
    <>
      <section className="blog_detail_section" data-aos="fade-up" data-aos-duration={2000} data-aos-delay={200} >
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="blog_info" data-aos="fade-up" data-aos-duration={2000} >
              <span className="date">March 23, 2023</span>
              <h1>A Hidden Oasis: Unwind Poolside at The Olive Tree Restaurant & Bar</h1>
              <div className="authore_block" data-aos="fade-up" data-aos-duration={1000} >
                <div className="authore">
                  <div className="img">
                    <img src={Blog1} alt="image" />
                  </div>
                  <div className="text">
                    <h4></h4>
                    <span></span>
                  </div>
                </div>
                <div className="blog_tag">
                  <span>Hisarönü, poolside dining, restaurant, bar, cocktails, drinks, sun loungers, swimming pool, relaxation, oasis, unwind, family-friendly, romantic dinner, sunset views</span>
                </div>
              </div>
              <p>
              Seeking a relaxing escape in the heart of Hisarönü? Look no further than The Olive Tree Restaurant & Bar! Our tranquil poolside setting offers the perfect oasis to unwind, soak up the sun, and indulge in delectable food and drinks.
              </p>
            </div>
            <div className="main_img">
              <img src={Blog2} alt="image" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Escape the Ordinary::</h2>
              <p>
              Step away from the hustle and bustle and discover a haven of serenity.  Our inviting poolside area provides the ideal setting to relax with friends or family. Take a dip in the refreshing pool, work on your tan on a comfortable sun lounger, or simply unwind with a good book.

              </p>
            </div>
            <div className="quote_block" data-aos="fade-up" data-aos-duration={1500} >
              <span className="q_icon">“</span>
              <h3>
              Escape the ordinary and discover your own poolside oasis at The Olive Tree Restaurant & Bar.  Book your table today and experience the perfect blend of relaxation and culinary delight!
              </h3>
              <span className="q_icon">”</span>
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Culinary Delights by the Pool::</h2>
              <p>
              While you relax, our attentive staff is on hand to cater to your every need.  Enjoy a refreshing poolside cocktail or indulge in a light lunch from our delectable menu. From fresh salads and wraps to tempting appetizers, we offer a variety of options to satisfy your cravings without leaving the poolside comfort.
 <br /> <br />
 <h6>Unwind and Savor the Evening:</h6>

 As the sun begins to set, the poolside area transforms into a magical setting for an unforgettable evening.  Enjoy a delicious dinner under the starlit sky, accompanied by the gentle sound of poolside relaxation.  The Olive Tree promises an evening of tranquility and pure indulgence.


              </p>
            </div>
            <div className="main_img" data-aos="fade-up" data-aos-duration={1500} >
              <iframe style={{ width: "100%", height: 500 }}
                src="https://www.youtube.com/embed/zIOt1faGd7g"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Neden tercih ediliyoruz?</h2>
              <ul>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    {" "}
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    30 Yıllık Deneyim
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Lezzetli Akdeniz Mutfağı
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Kusursuz Hizmet Anlayışı.{" "}
                  </p>
                </li>
              </ul>
            </div>
           
            <div className="blog_authore" data-aos="fade-up" data-aos-duration={1500} >
              <div className="social_media">
                <h3>Share this</h3>
                <ul>
                  <li>
                    <Link to="#">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
