import React from 'react'
import { Link } from 'react-router-dom'
import Menulist1 from '../../assets/images/menulist_01.png'
import Menustb1 from '../../assets/images/menulist-chateaubriand.jpg'
import Menustb2 from '../../assets/images/menulist-ribeye.jpg'
import Menustb3 from '../../assets/images/menulist-fillet-steak.jpg'
import Menustb4 from '../../assets/images/menulist-rossini.jpg'
import Menustb5 from '../../assets/images/menulist-flemignon.jpg'
import Menustb6 from '../../assets/images/menulist-special-beef.jpg'

function DishList() {
  return (
    <>
      <section className="row_am dishList_block">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_img dish_left text-center">
                <img className="img-fluid" src={Menulist1} alt="img" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_list">
                <ul>
                  <li>
                    <div className="img">
                      <img src={Menustb1} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Chateaubriand</h4>
                          <span className="new">NEW</span>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        For two person on request
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb2} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Dry Aged Ribeye</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Marinated grill vegatable, fresh parmentier potato, garlic butter,peppercorn sauce
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb3} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Fillet Steak</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Baked cherry tomatoes honey pesto, garlic butter, saffron rice, roast potato red wine & mushroom sauceralian lungfish sea, Shad angler arapaima
                          pencilsmelt.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb4} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Rossini</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Steak cooked with chicken liver pate in mushroom brandy sauce, panfried veg.&mash potato
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb5} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Flemignon</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Slice of steak cooking in a garlic, red wine & mushroom sauce, panfried veg. & mash potato
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb6} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Chef’s Special Beef</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Fillet steak topped w ith p rawns d ressed w ith a g arlic m ushroom
green pepper white wine cream sauce. Served with parmentier potato.                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <Link to="/reservation1" className="btn btn_primary">
                  book a table
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DishList