import React from 'react'
import { Link } from 'react-router-dom'
import Menulist1 from '../../assets/images/kebap.png'
import Menustb1 from '../../assets/images/menulist-Ottoman.jpg'
import Menustb2 from '../../assets/images/menulist-dicedlamb.jpg'
import Menustb3 from '../../assets/images/menulist-goulash.jpg'
import Menustb4 from '../../assets/images/menulist-BeefinAlePie.jpg'
import Menustb5 from '../../assets/images/menulist-PasaKebab.jpg'
import Menustb6 from '../../assets/images/menulist-islim.jpg'
import Menustb7 from '../../assets/images/menulist-liverSizzler.jpg'
import Menustb8 from '../../assets/images/menulist-BeefSizzler.jpg'

function DishList() {
  return (
    <>
      <section className="row_am dishList_block">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_img dish_left text-center">
                <img className="img-fluid" src={Menulist1} alt="img" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_list">
                <ul>
                  <li>
                    <div className="img">
                      <img src={Menustb1} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Ottoman Beef Goulash</h4>
                          <span className="new">NEW</span>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Mixed fresh veg & beef, red wine sauce topped parmesan cheese, saffron rice
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb2} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Diced Lamb</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Lamb in a minth & yoghurt sauce, parmentier potato
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb3} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Traditional Lamb Goulash</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Slow cooked with gravy sauce served with saffron rice.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb4} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Beef in Ale Pie</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Serve with chips & Veg                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb5} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Paşa Kebab</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Ground beef patty cooked in the oven with mashed potato, tomato, pepper & cheese
served with grilled mushroom, onion and demi glace sauce.                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb6} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Islim Kebap</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Clay baked beef casserole wrapped in chargrilled aubergine-served with veg.saffron rice and tatziki            </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb7} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Liver Sizzler</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Turkish style; pepper, tomato, shallots served with saffron rice & parmentier potato          </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb8} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Beef Sizzler</h4>
                        </div>
                        <div className="doted" />
                        <span className="price"></span>
                      </div>
                      <div className="description">
                        <p>
                        Turkish style; pepper, tomato, shallots served with saffron rice & parmentier potato                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <Link to="/reservation1" className="btn btn_primary">
                  book a table
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DishList