import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import reviewimg from '../../assets/images/review_01.png'
import reviewimg2 from '../../assets/images/review_02.png'
import reviewimg3 from '../../assets/images/review_03.png'
import reviewimg4 from '../../assets/images/review_04.png'
import reviewimg5 from '../../assets/images/review-genis.jpeg'
import reviewimg6 from '../../assets/images/review_06.png'
import reviewimg7 from '../../assets/images/review_07.png'
import reviewimg8 from '../../assets/images/review_08.png'
import videoplay from '../../assets/images/video_play.png'

function Review() {

  const [video, setvideo] = useState(false);
  const [video2, setvideo2] = useState(false);

  return (
    <>
      <section className="row_am review_section">
        <div className="container">
          <div className="title">
            <h2 className="review_text">reviews</h2>
          </div>
          <div className="review_list">
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                Amazing food, great atmosphere and wonderful staf. Will definately recommend and will return thank you!
                </p>
                <h3 className="name">- Marry Christina</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_video">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="video_box" onClick={() => setvideo(true)}>
                <Link to="#" onClick={() => setvideo(true)} className="popup-youtube play-button" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                  <img src={videoplay} alt="img" />
                </Link>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg3} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                Although not staying at the hotel, we always make a visit to The Olive Tree to eat in their restaurant. The setting is wonderful, watching from the outside upper terrace as the sun sets. The staff are fantastic and the food is equally as good. I chose the Lemon Sole with Prawns and couldn't believe how much fish came out on my plate. It was a great portion size! Anytime I return to Hisaronu, I will certainly be returning here to dine
                </p>
                <h3 className="name">- Jamie Leaning (Google Review)</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg4} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                We had a lovely meal at the Olive Tree whist on holiday. It is a little off the beaten track but is well worth the walk. It is very atmospheric and extremely peaceful. The staff are excellent and definitely know what they are doing, it has the feel of a top end restaurant. Would definitely recommend a visit if you are in the area, you won't be disapointed
                </p>
                <h3 className="name">- Mark Cunniffe (Google Review)</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_video">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="video_box">
                <Link to="#"  onClick={() => setvideo2(true)} className="popup-youtube play-button" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                  <img src={reviewimg5} alt="img" />
                </Link>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
            <div className="review_box with_text">
              <span className="quotes left_quote">
                <i className="icofont-quote-left" />
              </span>
              <div className="user_img">
                <img src={reviewimg3} alt="img" />
              </div>
              <div className="review">
                <div className="star">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
                <p>
                Samimi, Güleryüzlü işletme sahibi ve çalışanları ile lezzetli yemekleri, temiz ortamları ve hoş muhabbetleri sayesinde günün nasıl geçtiğini anlayamadık. Allah, bol ve bereketli kazançlar nasip etsin...
                </p>
                <h3 className="name">- Ali Kahraman (Google Review)</h3>
              </div>
              <span className="quotes right_quote">
                <i className="icofont-quote-right" />
              </span>
            </div>
          </div>
        </div>
      </section>

      {video &&
        <div className="modal fade youtube-video show" id="myModal" tabIndex={-1} role="dialog" style={{ paddingRight: 17, display: "block" }} aria-modal="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setvideo(false)}>
              <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setvideo(false)}>
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/8lmhsubRn7o?autoplay=1&mute=1" />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      }
      {video2 &&
        <div className="modal fade youtube-video show" id="myModal" tabIndex={-1} role="dialog" style={{ paddingRight: 17, display: "block" }} aria-modal="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setvideo2(false)}>
              <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setvideo2(false)}>
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/4EhzY9ryQv4?autoplay=1&mute=1" />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Review