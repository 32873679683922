import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo-site.png'
import MenuImg from '../../assets/images/right_menu_table.png'

function Main() {

  const [active, setActive] = useState();
  const [Nav, setNav] = useState(false);
  const [Home, setHome] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    closeMenu();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {active &&
        <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
      }
      <header className={`fixed ${menuOpen ? 'menu-open' : ''}`}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="image" />
            </Link>
            <button className={`navbar-toggler ${menuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon" onClick={() => setHome(Home === true ? false : true)}>
                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                    Home
                  </Link>
               {/*    <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span> */}
                {/*   <div className={`sub_menu ${isDropdownOpen ? 'open' : ''}`}>
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link to="/">Home Defoult</Link>
                      </li>
                      <li>
                        <Link to="/home2">Home Slider Hero</Link>
                      </li>
                      <li>
                        <Link to="/home3">Home Dish List</Link>
                      </li>
                      <li>
                        <Link target="_blank" to={"https://mediacity.co.in/flavoury/04"} >Home Burger</Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="/menulist1" onClick={handleMenuItemClick}>
                    MENU
                  </Link>
            {/*       <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/menulist1">Menu List 1</Link>
                      </li>
                      <li>
                        <Link to="/menulist2">Menu List 2</Link>
                      </li>
                      <li>
                        <Link to="/menulist3">Menu List 3</Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about" onClick={handleMenuItemClick}>
                    ABOUT US
                  </Link>
                </li>
            {/*     <li className="nav-item">
                  <Link className="nav-link" to="/ourchef" onClick={handleMenuItemClick}>
                    OUR CHEFS
                  </Link>
                </li> */}
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                    Medias
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/bloglist">Blog List</Link>
                      </li>
                      <li>
                        <Link to="/gallery">gallery</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://theolivetreestudios.com/" onClick={handleMenuItemClick}>
                    Hotel
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact" onClick={handleMenuItemClick}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item contact_number">
                  <Link className="nav-link" to="tel:+902526166361">
                    <span>book a table :</span> +90 252 616 63 61
                  </Link>
                </li>
                <li className="d-lg-none">
                  <Link to="/reservation1" className="btn btn_primary" onClick={handleMenuItemClick}>
                    FIND A TABLE
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action_bar">
              <Link to="/reservation1" className="btn btn_primary" onClick={handleMenuItemClick}>
                FIND A TABLE
              </Link>
              <div className="bar" onClick={() => setNav(true)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {Nav &&
        <div className={`right_sidemenu ${Nav && "active"}`}>
          <span className="close_rightmenu" onClick={() => setNav(false)}>
            <i className="icofont-close-circled" />
          </span>
          <div className="menu_inner_content">
            <Link to="#" className="side_logo">
              <img src={logo} alt="image" />
            </Link>
            <ul className="contact_listing">
              <li>
                <p className="sub_title">Call us</p>
                <div className="cnt_block">
                  <Link to="tel:+902526166361">+90 252 616 63 61</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
                <div className="cnt_block">
                  <Link to="tel:+905335808682">+90 533 580 86 82</Link>
                  <span>
                    <i className="icofont-whatsapp" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Email us</p>
                <div className="cnt_block">
                  <Link to="mailto:">info@theolivetreestudios.com</Link>
                  <span>
                    <i className="icofont-envelope-open" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Find us</p>
                <div className="cnt_block">
                  <p>Hisaronu Mahallesi Oludeniz Beldesi Fethiye / Muğla 48300 TURKEY</p>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
              </li>
            </ul>
            <Link to="#" className="btn btn_primary">
              book a table
            </Link>
            <figure className="tab_img">
              <img src={MenuImg} alt="img" />
            </figure>
          </div>
        </div>
      }
    </>
  )
}

export default Main