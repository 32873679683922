import React from 'react'
import { Link } from 'react-router-dom'
import Blog01 from '../../assets/images/blog_01.jpeg'
import Blog02 from '../../assets/images/blog_02.jpeg'
import Blog03 from '../../assets/images/blog_03.jpeg'

function Blog() {
  return (
    <>
      <section className="row_am blog_section position-relative">
        <div className="container">
          <div className="section_title text-center py_50" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">News &amp; updates</span>
            <h2>latest blog post</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="blog_post" data-aos="fade-up" data-aos-duration={1500}>
                <Link to="#" className="img">
                  <img src={Blog01} alt="img" className="img-fluid" />
                </Link>
                <div className="info">
                  <span className="date">17.04.2023</span>
                  <h3>
                    <Link to="/blogdetail1">
                    Celebrating 30th. Anniversary ✨✨✨
May 1994 - May 2024
                    </Link>
                  </h3>
                  <Link to="/blogdetail1" className="btn btn_text">
                    READ MORE <i className="icofont-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog_post mx-auto" data-aos="fade-up" data-aos-duration={1500} >
                <Link to="#" className="img">
                  <img src={Blog02} alt="img" className="img-fluid" />
                </Link>
                <div className="info">
                  <span className="date">12.04.2023</span>
                  <h3>
                    <Link to="/blogdetail2">
                    Unveiling the Culinary Delights of Fethiye at The Olive Tree Restaurant & Bar                     </Link>
                  </h3>
                  <Link to="/blogdetail2" className="btn btn_text">
                    READ MORE <i className="icofont-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog_post ml-auto" data-aos="fade-up" data-aos-duration={1500} >
                <Link to="#" className="img">
                  <img src={Blog03} alt="img" className="img-fluid" />
                </Link>
                <div className="info">
                  <span className="date">08.04.2023</span>
                  <h3>
                    <Link to="/blogdetail3">
                    Fethiye'nin Gözde Lezzet Durağı: The Olive Tree Restaurant & Bar                    </Link>
                  </h3>
                  <Link to="/blogdetail3" className="btn btn_text">
                    READ MORE <i className="icofont-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog