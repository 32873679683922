import { Link } from 'react-router-dom'
import React, { useState } from "react";
import Slider from 'react-slick';
import BG from '../../assets/images/video_poster.png'
import icon01 from '../../assets/images/title_icon.png'
import menu01 from '../../assets/images/menu-dinner.jpeg'
import menu02 from '../../assets/images/menu-brunch.jpeg'
import menu03 from '../../assets/images/menu-salad.jpeg'
import menu04 from '../../assets/images/menu-wine.jpeg'
import menuimg from '../../assets/images/menu.png'
import videoimg from '../../assets/images/video_text.png'
import playimg from '../../assets/images/play.png'

const DeliciousMenu = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

function Deliciousmenu() {

  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <section className="row_am delicous_menu">
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="icon">
              <img src={icon01} alt="img" />
            </span>
            <span className="sub_text">Delicious menu</span>
            <h2>
              A restaurant with Traditional delicious menu. <br />
              Fine dining, Authentic Tastes &amp; <br />
              Authentic Atmosphere, with our steaks.
            </h2>
          </div>
          <div className="menu_listing row_am">
            <div className="menu_listing_block" data-aos="fade-up" data-aos-duration={1500} >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>BBQ</h3>
                </div>
                <div className="list_running">
                  <Slider className=" menuTextSlider" {...DeliciousMenu} slidesToShow={4} autoplay={true} autoplaySpeed={500} arrows={false}>
                    <div className="item">
                      <div className="wrp">
                        <span>Chateaubriand</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Dry Aged Ribeye</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Fillet Steak</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Rossini</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Flemignon</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu01} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menulist1">BBQ</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Chateaubriand</span>
                    </li>
                    <li>
                      <span>Dry Aged Ribeye</span>
                    </li>
                    <li>
                      <span>Fillet Steak</span>
                    </li>
                    <li>
                      <span>Rossini</span>
                    </li>
                    <li>
                      <span>Flemignon</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menulist1" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="menu_listing_block" data-aos="fade-up" data-aos-duration={1500} >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>SALAD</h3>
                </div>
                <div className="list_running">
                  <Slider className="menuTextSlider" slidesToShow={4} autoplay={true} autoplaySpeed={500} arrows={false} >
                    <div className="item">
                      <div className="wrp">
                        <span>Caeser</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Tuna Fish</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>AlloumiSalad</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Grilled Chicken</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Cajun Salmon</span>
                      </div>
                    </div>
          
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu02} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menulist1">SALAD</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Caeser</span>
                    </li>
                    <li>
                      <span>Tuna Fish</span>
                    </li>
                    <li>
                      <span>AlloumiSalad</span>
                    </li>
                    <li>
                      <span>Grilled Chicken</span>
                    </li>
                    <li>
                      <span>Cajun Salmon</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menulist1" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="menu_listing_block" data-aos="fade-up" data-aos-duration={1500} >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>DESSERT</h3>
                </div>
                <div className="list_running">
                  <Slider className="menuTextSlider" slidesToShow={4} autoplay={true} autoplaySpeed={500} arrows={false}>
                    <div className="item">
                      <div className="wrp">
                        <span>Baklava</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Tiramisu</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Cheesecake</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Creme Brulee</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Ice Cream</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Pancake</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu03} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menulist1">DESSERT</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Baklava</span>
                    </li>
                    <li>
                      <span>Tiramisu</span>
                    </li>
                    <li>
                      <span>Cheesecake</span>
                    </li>
                    <li>
                      <span>Creme Brulee</span>
                    </li>
                    <li>
                      <span>Ice Cream</span>
                    </li>
                    <li>
                      <span>Pancake</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menulist1" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="menu_listing_block" data-aos="fade-up" data-aos-duration={1500} >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>WINES</h3>
                </div>
                <div className="list_running">
                  <Slider className="menuTextSlider" slidesToShow={4} autoplay={true} autoplaySpeed={500} arrows={false}>
                    <div className="item">
                      <div className="wrp">
                        <span> 
                         Red Wine
                        </span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>
                          White Wine
                        </span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>
                          Rose Wine
                        </span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>
                          Sparkling Wine
                        </span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>
                          Dessert Wine
                        </span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu04} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menulist1">WINES</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Red Wine</span>
                    </li>
                    <li>
                      <span> White Wine</span>
                    </li>
                    <li>
                      <span> Rose Wine</span>
                    </li>
                    <li>
                      <span>Sparkling Wine</span>
                    </li>
                    <li>
                      <span>Dessert Wine</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menulist1" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="video_wraper">
          <div className="action_box" style={{ backgroundImage: `url(${BG})`}} >
            <div className="play_vtn d-flex justify-content-center align-items-center" onClick={() => setytShow(true)}>
              <div className="round_anim">
                <img src={videoimg} alt="img" />
              </div>
              <div className="action_btn">
                <Link to="#" className="popup-youtube play-button" data-url="https://www.youtube.com/embed/FupgvXpYBrw?autoplay=1&mute=1" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                  <img src={playimg} alt="img" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {ytshow &&
        <div className="modal fade youtube-video show" id="myModal" tabIndex={-1} role="dialog" style={{ paddingRight: 17, display: "block" }} aria-modal="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytShow(false)}>
              <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setytShow(false)}>
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/FupgvXpYBrw?autoplay=1&mute=1" />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Deliciousmenu