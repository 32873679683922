import React from "react";
import { Link } from "react-router-dom";
import Blog1 from '../../assets/images/logo-site.png'
import Blog2 from '../../assets/images/blog-single.jpeg'

function BlogDetail() {
  return (
    <>
      <section className="blog_detail_section" data-aos="fade-up" data-aos-duration={2000} data-aos-delay={200} >
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="blog_info" data-aos="fade-up" data-aos-duration={2000} >
              <span className="date">March 23, 2023</span>
              <h1>   Celebrating 30th. Anniversary ✨✨✨
May 1994 - May 2024</h1>
              <div className="authore_block" data-aos="fade-up" data-aos-duration={1000} >
                <div className="authore">
                  <div className="img">
                    <img src={Blog1} alt="image" />
                  </div>
                  <div className="text">
                    <h4>The Olive Tree Restaurant</h4>
                    <span>Admin</span>
                  </div>
                </div>
                <div className="blog_tag">
                  <span>anniversary 
                  </span>
                </div>
              </div>
              <p>
              We are proudly celebrating the 30th anniversary of the establishment of our restaurant, every day of which has been full of achievements for us. Since 1994, we are proud to be one of the best in its field with its ever-increasing service quality. We wholeheartedly agree with the saying that great things are accomplished by employees who dare to do them, and we believe that we will achieve even greater successes together in the future. We would like to thank the employees of The Olive Tree Family who have been with us since our establishment and who have devoted their experience and knowledge, heart and labor to the development of The Olive Tree Restaurant. While expressing our sincere gratitude to our guests who have supported us during these 30 years, our goal is to win the appreciation and admiration of those who cooperate with us by offering the best, much better, and to win the appreciation and admiration of those who join us in the future... Thank you .... ❤️
              </p>
            </div>
            <div className="main_img">
              <img src={Blog2} alt="image" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>30'uncı Kuruluş Yıl Dönümü</h2>
              <p>
              Restoranımızın kuruluşunun, bizler için her günü başarılarla dolu olan 30. Yılını gururla kutluyoruz. 1994 yılından bu yana sürekli yükselen hizmet kalitesiyle alanında en iyilerinden olmanın da haklı mutluluğunu yaşıyoruz. Harikulade işler, onu yapma cesaretini gösteren çalışanlar tarafından başarıyla gerçekleştirilirler sözüne yürekten katılıyor, gelecekte de daha büyük başarılara hep birlikte imza atacağımıza inanıyoruz. Kuruluşumuzdan bu yana bizimle beraber olan tecrübe ve bilgisini, kalbi ve emeğini The Olive Tree Restaurant’ın gelişimi için adayan The Olive Tree Ailesi çalışanlarına çok teşekkür ediyoruz. Geçen bu 30 yıl içerisinde bize destek veren misafirlerimize gönülden şükranlarımızı sunarken, hedefimiz; iyiyi, çok daha iyiyi sunarak, bizimle işbirliği yapan, dirsek temasında bulunanların gelecekte de takdir ve beğenilerini kazanmaktır… 

              </p>
            </div>
            <div className="quote_block" data-aos="fade-up" data-aos-duration={1500} >
              <span className="q_icon">“</span>
              <h3>
              İyi ki varsınız.... ❤️ 
30 Yıllık Lezzet Yolculuğu: The Olive Tree Restaurant'ın Gururlu Kutlaması!
              </h3>
              <span className="q_icon">”</span>
            </div>
           <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Akdeniz Mutfağı... </h2>
              <p>
              1994 yılından beri, The Olive Tree Restaurant Fethiye'de lezzet tutkunlarına unutulmaz deneyimler sunuyor. Bu yıl, 30. yıl dönümümüzü gururla kutluyoruz! Bu özel vesileyle, bize bu muhteşem yolculukta eşlik eden tüm misafirlerimize ve The Olive Tree Ailesi'nin özverili çalışanlarına en içten teşekkürlerimizi sunmak isteriz.

Yıllar boyunca, kusursuz hizmet anlayışımız ve lezzetli Akdeniz mutfağımızla Fethiye'de saygın bir yer edindik. Her zaman en iyisini sunma çabamız ve yenilikçi bakış açımız sayesinde, misafirlerimizden aldığımız takdir ve övgüler bize ilham kaynağı olmaya devam ediyor.
              </p>
            </div> 
            <div className="main_img" data-aos="fade-up" data-aos-duration={1500} >
              <iframe style={{ width: "100%", height: 500 }}
                src="https://www.youtube.com/embed/7y6NC7Aqk1s"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="" />
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <p>
              Bu 30 yıllık süreçte, The Olive Tree Restaurant'ın başarısında emeği geçen tüm çalışanlarımıza minnettarız. Tecrübeleri, bilgileri ve özverileriyle, restoranımızın her zaman en iyi şekilde hizmet vermesini sağladılar. Bu özel günde, The Olive Tree Ailesi'nin her bir üyesine şükranlarımızı sunuyoruz.
              Geleceğe baktığımızda, The Olive Tree Restaurant'ın çıtayı daha da yükseltmeye devam edeceğine inanıyoruz. Hedefimiz, her zaman en iyiyi sunarak, misafirlerimizin damak zevkine hitap eden lezzetler ve kusursuz bir hizmet anlayışı ile Fethiye'nin en gözde restoranı olmaya devam etmek.

Bu 30 yıllık lezzet yolculuğunun bir sonraki durağına birlikte adım atmaya hazırız! The Olive Tree Restaurant'ta sizleri de unutulmaz bir Akdeniz mutfağı deneyimi yaşamaya bekliyoruz.
              </p>
            </div>
            <div className="info" data-aos="fade-up" data-aos-duration={1500}>
              <h2>Neden tercih ediliyoruz?</h2>
              <ul>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    {" "}
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    30 Yıllık Deneyim
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Lezzetli Akdeniz Mutfağı
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration={1500}>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled" />
                    </span>{" "}
                    Kusursuz Hizmet Anlayışı.{" "}
                  </p>
                </li>
              </ul>
            </div>
            <div className="blog_authore" data-aos="fade-up" data-aos-duration={1500} >
              <div className="social_media">
                <h3>Share this</h3>
                <ul>
                  <li>
                    <Link to="#">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
